import React from 'react';
import {Link} from 'react-router-dom';
import FourOhFour from '@cimpress/react-components/lib/FourOhFour';

export default class ContactUsPage extends React.Component {
    render() {
        return (
          <div className='row'>
            <div className='col-md-12'>
              <br />
              <FourOhFour
                message={
                  <div>
                                Oops! Something has gone wrong and the page you were looking for could not be {' '}
                                displayed.
                                Try <Link to={"/"}>the homepage</Link>.
                  </div>
                        }
                    />
            </div>
          </div>
        );
    }
}