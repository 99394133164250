import auth from '../../auth/auth';
import moment from 'moment-timezone';

export const actionTypes = {
    loginRequest: 'LOGIN_REQUEST',
    loginFailure: 'LOGIN_FAILURE',
    loginSuccess: 'LOGIN_SUCCESS',
    logout: 'LOGOUT'
};

export const login = () => {
    return (dispatch) => {

        dispatch(loginRequest());

        if ( auth.isLoggedIn() ) {
            dispatch(loginSuccess(auth.getProfile()));
            return Promise.resolve();
        }

        return auth
            .login(window.location.pathname + window.location.search)
            .then(() => {
                let profile = auth.getProfile();
                if ( profile ) {
                    dispatch(loginSuccess(auth.getProfile(), auth.getAccessToken()));
                } else {
                    dispatch(loginFailure('no profile present'));
                }
            })
            .catch(catchErr => {
                return dispatch(loginFailure(catchErr))
            });
    };
};

export const logout = () => {
    return (dispatch) => {
        dispatch(logoutRequest());

        localStorage.removeItem('state');

        auth.logout('/');
    }
};

const logoutRequest = () => {
    return {
        type: actionTypes.logout,
        receivedAt: moment.utc().format()
    };
};

const loginRequest = () => {
    return {
        type: actionTypes.loginRequest,
        receivedAt: moment.utc().format()
    };
};

const loginFailure = (error) => {
    return {
        type: actionTypes.loginFailure,
        receivedAt: moment.utc().format(),
        error: error
    };
};

const loginSuccess = (data, token) => {
    return {
        type: actionTypes.loginSuccess,
        receivedAt: moment.utc().format(),
        data: {
            profile: data,
            token: token
        }
    };
};
