module.exports.defaultAjaxReducer = defaultAjaxReducer;

function defaultAjaxReducer(actionRequest, actionSuccess, actionFailure) {

    return (state = {}, action) => {

        if ( action.type === actionRequest ) {
            console.log(action.type);
            return Object.assign({}, state, {
                isFetching: true,
                error: undefined,
                data: undefined
            });
        }

        if ( action.type === actionSuccess ) {
            console.log(action.type);
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
                error: undefined
            });
        }

        if ( action.type === actionFailure ) {
            console.log(action.type);
            return Object.assign({}, state, {
                isFetching: false,
                error: action.error,
                data: undefined
            });
        }

        return state;
    };
}
