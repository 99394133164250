import React from 'react';
import PropTypes from 'prop-types';

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux';
import { login, logout } from './redux/actions/auth';

import ContactUsPage from './pages/generic/ContactUsPage';
import NotFoundPage from './pages/generic/NotFoundPage';
import Loading from './components/generic/Loading';
import './App.css';

import { withTranslation } from 'react-i18next';

class App extends React.Component {

    componentDidMount() {
        this.onLoginClicked();
    }

    onLoginClicked() {
        if (!this.props.authentication || !this.props.authentication.data) {
            if (!this.props.authentication || !this.props.authentication.isFetching) {
                this.props.login();
            }
        }
    }

    onLogoutClick() {
        this.props.logout();
    }

    render() {
        let content = null;

        if (this.props.authentication.isFetching) {
            content = <Loading message={'Authenticating, please wait...'} />
        } else {
            content = (
              <Switch>
                <Route exact path={"/"} component={ContactUsPage} />
                <Route path={"/contactUs"} component={ContactUsPage} />
                <Route path={"/gatefold"} component={ContactUsPage} />
                <Route path={"/demo-comments"} component={ContactUsPage} />
                <Route path={"/demo-fulfillers-components"} component={ContactUsPage} />
                <Route path={"/demo-fulfiller-logo"} component={ContactUsPage} />
                <Route path={"/demo-templates"} component={ContactUsPage} />
                <Route path={"/demo-users"} component={ContactUsPage} />
                <Route path={"/demo-baywatch"} component={ContactUsPage} />
                <Route path={"/demo-tagliatelle"} component={ContactUsPage} />
                <Route component={NotFoundPage} />
              </Switch>
            );
        }

        return (
          <BrowserRouter>
            <div>
              <div className='container container-fluid container-min-height' role='main'>
                {content}
              </div>
            </div>
          </BrowserRouter>
        );

    }
}

App.propTypes = {
    children: PropTypes.object,
    authentication: PropTypes.any,
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
};

App.contextTypes = {
    store: PropTypes.object
};

const mapStateToProps = state => {
    return {
        authentication: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        // Authentication
        login: () => dispatch(login()),
        logout: () => dispatch(logout()),
    }
};

export default withTranslation('translations')(connect(
    mapStateToProps,
    mapDispatchToProps
)(App));
