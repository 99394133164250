import React, {Fragment} from 'react';

import './ContactUsPage.css'
import {withTranslation} from 'react-i18next';

class ContactUsPage extends React.Component {
    render() {
        const email = 'mailto:TrdelnikSquad%40cimpress.com?subject=Feedback/Questions';
        return (
          <div>

            <div className={"white-body"}>
              <div className='col-md-6'>
                <div className={"card card-minimal"}>
                  <div className={'card-header'}>
                    <h5>
                      <i className='fa fa-life-ring text-warning' />&nbsp;
                      Support Request
                    </h5>
                  </div>
                  <div className={"card-block"}>
                    <p>Have a support related issue or question? If you need help with it, please reach out. Send
                      an e-mail describing your case and we'll reach out as soon as possible.</p>
                    <ul>
                      <li><i className='fa fa-envelope text-warning' />&nbsp;
                        <a href={"mailto:PlatformSupport%40cimpress.com"}>Platform Support</a></li>
                      <li><i className='fa fa-slack text-warning' />&nbsp;#mcp-platform-support</li>
                    </ul>
                    <br />
                    <div className='alert alert-info'>
                      <Fragment>
                        <p>For <strong>URGENT or CRITICAL</strong> requests, that require <strong>immediate</strong>
                          &nbsp;attention, you can reach out to our on-call support</p>
                        <ul>
                          <li><i className='fa fa-envelope text-danger' />&nbsp;
                            <a href={"mailto:trdelnik-support%40cimpress.pagerduty.com"}>Trdelnik
                                    On-Call</a></li>
                        </ul>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className={"card card-minimal"}>
                    <div className={'card-header'}>
                      <h5>
                        <i className='fa fa-send text-success' />&nbsp;
                        General Request
                      </h5>
                    </div>
                    <div className={"card-block"}>
                      <p>Have any questions, feedback or comments? We'd love to hear about them!</p>
                      <p>We'll do our best to respond as soon as possible during our business hours from
                        9am CET till 6pm CET.</p>
                      <ul>
                        <li><i className='fa fa-envelope text-success' />&nbsp;<a href={email}>Trdelnik Squad</a></li>
                        <li><i className='fa fa-slack text-success' />&nbsp;#mcp-platform-support, mention @trdelnik</li>
                        <li><i className='fa fa-slack text-success' />&nbsp;#orders-support, mention @trdelnik</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

export default withTranslation()(ContactUsPage);
