import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import {Provider} from 'react-redux'
import app from './redux/index';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

let store = createStore(
    app,
    compose(
        applyMiddleware(thunk),
        window.devToolsExtension
            ? window.devToolsExtension()
            : f => f
    )
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
    , document.getElementById('root'));

registerServiceWorker();
