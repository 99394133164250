import {actionTypes} from '../actions/auth';

import _helper from './_helper';

const AuthResponse = _helper.defaultAjaxReducer(
    actionTypes.loginRequest,
    actionTypes.loginSuccess,
    actionTypes.loginFailure
);

export default AuthResponse;
